/* Footer
================================================== */
#sponsors-section {
	padding: 0 2rem;
	background-color: $light-grey;
	.row {
		margin-top: 4rem;
		margin-bottom: 4rem;
		padding: 2rem 0 0 0;
		background-color: $white;
	}
	.moduletable {
		padding: 1rem 0;
	}
	.title {
		&--image-align {
			@include bp_max(mlarge) {
				flex-direction: column;
			}
			img {
				margin-top: -6px;
				@include bp_max(mlarge) {
					margin-top: 0.25rem !important;
				}
			}
		}
	}
}

footer {
	padding: 3rem 0 2rem;
	background-color: $dark-grey;
	@include bp(xlarge) {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}
	@include bp(xxxlarge) {
		padding-right: 0;
		padding-left: 0;
	}
	* {
		color: $white;
	}
	h3 {
		font-size: 1.2rem;
	}
	a {
		&:hover {
			color: $quaternary-colour;
		}
	}
	.columns {
		@include bp_max(mlarge) {
			margin: 0.5rem 0;
		}
	}
	.moduletable {
		margin: 1rem 0;
		@include bp(medium) {
			margin: 0;
		}
	}
	.nav {
		.mod-menu__sub {
			display: none !important;
		}
		li {
			margin: 0.5rem 0;
			text-align: left;
		}
		a {
			display: inline-flex;
			justify-content: flex-start;
			padding: 0;
			font-size: 0.9rem;
			color: $white;
			text-transform: capitalize;
			font-weight: normal;
			&:hover {
				color: $quaternary-colour;
			}
		}
	}
	.opening-times {
		@include bp(xxlarge) {
			margin-right: 4rem;
		}
		div {
			display: flex;
			justify-content: space-between;
		}
		span {
			display: inline-flex;
			min-width: 100px;
		}
	}
}

.footer {
	&__logos {
		@include bp(xlarge) {
			text-align: right;
		}
	}
	&__logo {
		margin-bottom: 2rem;
	}
	&__social {
		margin-top: 2rem;
		ul {
			@include bp(large) {
				margin-left: auto;
			}
		}
		svg {
			* {
				fill: $white;
			}
		}
		a {
			&:hover {
				svg {
					* {
						fill: $secondary-colour;
					}
				}
			}
		}
	}
	&__bottom {
		align-items: center;
		margin-top: 2rem;
		.columns {
			@include bp_max(large) {
				display: flex;
				justify-content: center;
			}
		}
	}
	&__signature {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 0.8rem;
		text-transform: uppercase;
		font-weight: 600;
		@include bp(medium) {
			justify-content: flex-end;
			flex-direction: row;
		}
		a {
			display: flex;
		}
		h3 {
			@include bp_max(medium) {
				margin-bottom: 0.5rem;
			}
		}
		img {
			margin-left: 1rem;
			width: 12rem;
			@include transition($global-transition);
		}
	}
}

#wrbm-footer-div {
	width: 100%;
}
