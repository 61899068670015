/* Header
================================================== */
header {
	position: absolute;
	top: 0;
	z-index: 90;
	width: 100%;
	transition: $global-transition;
	@include bp(medium) {
		position: fixed;
	}
	.row {
		max-width: 100%;
		align-items: center;
		@include bp_max(medium) {
			flex-direction: column;
		}
		/*@include bp(medium) {
      padding: 0 1rem;
    }
    @include bp(xlarge) {
      padding: 0;
    }
    @include bp(xxlarge) {
      padding: 0 1rem;
    }*/
	}
	.shrink & {
		@include bp_max(medium) {
			position: fixed;
		}
		.header__logo,
		.header__right {
			img {
				@include bp(medium) {
					height: 48px;
				}
			}
		}
		.header__wrap {
			@include bp(medium) {
				padding: 0.5rem 0;
			}
		}
	}
	.main-buttons {
		display: inline-flex;
		margin-left: auto;
		.moduletable {
			padding: 0.25rem;
		}
		.button,
		p {
			margin: 0;
		}
		.button {
			&--book-stand {
				@include bp_max(medium) {
					display: none;
				}
			}
		}
	}
}
.header {
	&__wrap {
		padding: 1rem 0;
		background-color: $white;
		transition: $global-transition;
	}
	&__logo,
	&__right {
		p {
			margin: 0;
		}
		a {
			display: flex;
		}
		img {
			transition: all 500ms ease-in-out;
		}
	}
	&__logo {
		position: relative;
		z-index: 20;
		@include bp(xxlarge) {
			margin-right: 4rem;
		}
		@include bp_max(large) {
			flex-grow: 2;
		}
		img {
			height: 72px;
			@include bp_max(medium) {
				width: 100%;
			}
		}
	}
	&__right {
		p {
			display: flex;
			justify-content: end;
		}
		a {
			@include bp_max(medium) {
				margin-top: 1rem;
			}
		}
		img {
			height: 88px;
		}
	}
}
